import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importa tus archivos de traducción
import translationEN from '../locales/en/index.json';
import translationES from '../locales/es/index.json';

// Los recursos de traducción
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

i18n
  // Conecta el plugin de react-i18next al proyecto de i18next
  .use(initReactI18next)
  // Detecta el idioma del usuario
  .use(LanguageDetector)
  // Pasa la configuración a i18next
  .init({
    resources,
    fallbackLng: 'en', // idioma a cargar si el actual no está disponible
    debug: true, // Muestra información de depuración en la consola
    interpolation: {
      escapeValue: false, // No es necesario escapar de xss en react por defecto
    },
  });

export default i18n;