// extracted by mini-css-extract-plugin
export var StyledHam = "Hamburguer-module--StyledHam--82261";
export var active = "Hamburguer-module--active--f3f9c";
export var customDot = "Hamburguer-module--custom-dot--7e64b";
export var line = "Hamburguer-module--line--c424e";
export var line1 = "Hamburguer-module--line1--451c4";
export var line2 = "Hamburguer-module--line2--6226f";
export var line3 = "Hamburguer-module--line3--cee16";
export var notMobile = "Hamburguer-module--notMobile--aee43";
export var onlyMobile = "Hamburguer-module--onlyMobile--9f291";
export var slickActive = "Hamburguer-module--slick-active--3d640";