import { Link } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import React, { useState, Fragment } from "react";
import * as styles from "./header.module.scss";
import Logo2 from "../../../images/logo3.png";
import { useTranslation } from 'react-i18next';
import { Hamburguer } from "./Hamburguer";

const Header = ({ siteTitle, ...props }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language === "en" ? "EN" : "ES");
  const [isOpened, setIsOpened] = useState(false);
  console.log("isOpen =>", isOpened);

  const handleLanguage = () => {
    const nextLanguage = i18n.language === "en" ? "es" : "en";
    i18n.changeLanguage(nextLanguage);
    setLang(i18n.language === "en" ? "EN" : "ES");
  };

   // Función para establecer el idioma en inglés
   const setEnglishLanguage = () => {
    i18n.changeLanguage("en");
  };

  // Llamada a la función para establecer el idioma en inglés al cargar el componente
  React.useEffect(() => {
    setEnglishLanguage();
  }, []);

  return (
    <Fragment>
      <nav class={`${styles.navBoostrap} navbar sticky-top navbar-expand-lg bg-body-tertiary`}>
        <div className={styles.container}>
            <button
              className={styles.ButtonLang}
              onClick={() => handleLanguage()}
              aria-label={`Cambiar idioma a ${lang}`}
              >
                <span>{lang}</span>
            </button>
          <Link className="navbar-brand justify-content-start" to="#">
            <img src={Logo2} alt="BigSur"/>
          </Link>
          <button
            class="navbar-toggler notMobile"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <Hamburguer
              opened={isOpened}
              clicked={() => setIsOpened(!isOpened)}
          />

          <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul class={`${styles.ulNavBoostrap} navbar-nav notMobile`}>
              <li class="nav-item">
                <a class="nav-link" href="/#about">
                  {t('navbar.about')}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/#difference">
                  {t('navbar.ourWork')}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/#environmental">
                  {t('navbar.ourCommitment')}
                </a>
              </li>
              {/* <li class="nav-item">
                <a class="nav-link" href="/#founding">
                  {t('navbar.team')}
                </a>
              </li> */}
              <li class="nav-item">
                <a class="nav-link" href="/#joinUs">
                  {t('navbar.joinUs')}
                </a>
              </li>
            </ul>  
          </div>
        </div>
      </nav>

      <nav className={`${styles.wrapper} ${isOpened ? styles.active : ""}`}>
        <ul className={styles.mobileLinks}>
          <li>
            <Link to="/#about" onClick={() => setIsOpened(!isOpened)}>
              {t('navbar.about')}
            </Link>
          </li>
          <li>
            <Link to="/#difference" onClick={() => setIsOpened(!isOpened)}>
              {t('navbar.ourWork')}
            </Link>
          </li>
          <li>
            <Link to="/#environmental" onClick={() => setIsOpened(!isOpened)}>
              {t('navbar.ourCommitment')}
            </Link>
          </li>
          {/* <li>
            <Link to="/#founding" onClick={() => setIsOpened(!isOpened)}>
              {t('navbar.team')}
            </Link>
          </li> */}
          <li>
            <Link to="/#joinUs" onClick={() => setIsOpened(!isOpened)}>
              {t('navbar.joinUs')}
            </Link>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
