import React from "react";
import * as styles from "./Hamburguer.module.scss";

export const Hamburguer = ({ opened, clicked }) => {
  return (
    <div
      role="presentation"
      className={`${styles.StyledHam} ${opened ? styles.active : ""}`}
      onClick={clicked}
      onKeyDown={clicked}
    >
      <span className={`${styles.line} ${styles.line1}`}></span>
      <span className={`${styles.line} ${styles.line2}`}></span>
      <span className={`${styles.line} ${styles.line3}`}></span>
    </div>
  );
};
