import React from "react";
import PropTypes from "prop-types";
import Container from "./container";
import "normalize.css";

import "./layout.scss";
import Header from "./Nav/header";
import Footer from "./Nav/footer";
import GlobalStyles from "../../styles/GlobalStyles";

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Header />
      <Container>{children}</Container>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
