// extracted by mini-css-extract-plugin
export var ButtonLang = "header-module--ButtonLang--0cc94";
export var active = "header-module--active--6c85b";
export var container = "header-module--container--eb8da";
export var customDot = "header-module--custom-dot--16bac";
export var hamburger = "header-module--hamburger--c675c";
export var header = "header-module--header--47503";
export var headerNav = "header-module--headerNav--51054";
export var mobileLinks = "header-module--mobileLinks--e95ee";
export var mobileLinksSubList = "header-module--mobileLinksSubList--a516b";
export var navBoostrap = "header-module--navBoostrap--6ddf0";
export var notMobile = "header-module--notMobile--e6241";
export var onlyMobile = "header-module--onlyMobile--982aa";
export var open = "header-module--open--a2bb0";
export var open1 = "header-module--open1--e6ef3";
export var open2 = "header-module--open2--c763c";
export var open3 = "header-module--open3--4d70e";
export var slickActive = "header-module--slick-active--65fa6";
export var ulNavBoostrap = "header-module--ulNavBoostrap--1e607";
export var wrapper = "header-module--wrapper--5bb7b";