import React from "react";
import Layout from "./src/components/UI/layout";
import { I18nextProvider } from 'react-i18next';
import i18n from './src/i18n/config'; // Ajusta la ruta según sea necesario

// fonts


// bootstrap import
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// slick import
import "slick-carousel/slick/slick.css";
import 'slick-carousel/slick/slick-theme.css';
import "slick-carousel/slick/slick.min.js";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export const onClientEntry = () => {
  // Create a link element for Google Fonts
  const link = document.createElement('link');
  link.href =
    'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Open+Sans:wght@300;400;700&family=Urbanist:wght@100;400;700&display=swap';
  link.rel = 'stylesheet';

  // Append the link element to the head of the document
  document.head.appendChild(link);
};

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};
