import React from "react";
import * as styles from "./footer.module.scss";
import { useTranslation } from 'react-i18next';

const Footer = ({ opened, clicked }) => {
  //Traducciones
  const { t } = useTranslation();
  return (
    <footer className={styles.footerSection}>
      <div className="container">
        <div className="row">
          <div className={`${styles.footerSectionLeftCol} col-12 col-md`}>
           {/* <div className={styles.footerSection__social}>
              <ul>
                <li>
                  <a href="https://www.linkedin.com/company/bigsur-mining-facilities/" aria-label="Follow on Linkedin" target="_blank" rel="noopener noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="41"
                      viewBox="0 0 40 41"
                      fill="none"
                      aria-hidden="true"
                    >
                      <path
                        d="M15.0291 28.0001H11.2973V16.3165H15.0291V28.0001ZM13.1612 14.7228C11.9679 14.7228 11 13.7618 11 12.6017C11 12.0444 11.2277 11.51 11.633 11.1159C12.0383 10.7219 12.588 10.5005 13.1612 10.5005C13.7344 10.5005 14.2841 10.7219 14.6894 11.1159C15.0947 11.51 15.3224 12.0444 15.3224 12.6017C15.3224 13.7618 14.3541 14.7228 13.1612 14.7228ZM28.996 28.0001H25.2722V22.3126C25.2722 20.9571 25.2441 19.2188 23.332 19.2188C21.3918 19.2188 21.0945 20.6915 21.0945 22.2149V28.0001H17.3667V16.3165H20.9458V17.9103H20.998C21.4963 16.9923 22.7133 16.0235 24.5289 16.0235C28.3057 16.0235 29 18.4415 29 21.5821V28.0001H28.996Z"
                        fill="white"
                        
                      />
                      <circle cx="20" cy="20.5" r="19.5" stroke="white" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/bigsurmining/"  aria-label="Follow on Instagram" alt="Follow on Instagram" target="_blank" rel="noopener noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="41"
                      viewBox="0 0 40 41"
                      fill="none"
                      aria-hidden="true"
                    >
                      <path
                        d="M24.2379 10.5H15.2504C12.3504 10.5 10 12.8508 10 15.7504V24.7379C10 27.6379 12.3508 29.9883 15.2508 29.9883H24.2375C27.1375 29.9883 29.4883 27.6375 29.4883 24.7375V15.7504C29.4883 12.8504 27.1379 10.5 24.2379 10.5Z"
                        stroke="white"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M19.7442 24.9484C22.3422 24.9484 24.4484 22.8422 24.4484 20.2442C24.4484 17.6462 22.3422 15.54 19.7442 15.54C17.1462 15.54 15.04 17.6462 15.04 20.2442C15.04 22.8422 17.1462 24.9484 19.7442 24.9484Z"
                        stroke="white"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M25.4562 15.5398C26.0128 15.5398 26.4641 15.0885 26.4641 14.5318C26.4641 13.9752 26.0128 13.5239 25.4562 13.5239C24.8995 13.5239 24.4482 13.9752 24.4482 14.5318C24.4482 15.0885 24.8995 15.5398 25.4562 15.5398Z"
                        fill="white"
                      />
                      <circle cx="20" cy="20.5" r="19.5" stroke="white" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            */}
           <div className={styles.footerSection__terms}>
              <ul>
                <li>
                  <a href="#">{t('Footer.Service')}</a>
                </li>
                <li>
                  <a href="#">{t('Footer.Privacy')}</a>
                </li>
              </ul>
          </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
